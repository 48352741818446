import { Environment } from '@app/shared/interfaces/environment';

export const environment: Environment = {
  name: 'production',
  development: false,
  sentry: true,
  oauth: true,
  domain: 'https://chat.savandbros.com',
  api: 'https://chat-api.savandbros.com/api/',
  id: 'https://id.savandbros.com/',
  pusher: {
    key: 'f2afbd49d682083d7a04',
    host: 'kanal.savandbros.com',
    cluster: '',
  },
  firebase: true,
};
